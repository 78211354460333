import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import { Provider } from 'react-redux'
import store from './redux/store'
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
// import i18n from "i18next";
// import LanguageDetector from "i18next-browser-languagedetector";
// import {
//   // useTranslation,
//   initReactI18next,
// } from "react-i18next";
// import HttpApi from "i18next-http-backend";
// i18n
//   .use(initReactI18next) // passes i18n down to react-i18next
//   .use(LanguageDetector)
//   .use(HttpApi)
//   .init({
//     supportedLngs: ["en", "ar", "fr"],
//     lng: "en", // if you're using a language detector, do not define the lng option
//     fallbackLng: "en",
//     detection: {
//       order: ["htmlTag", "cookie", "localStorage", "path", "subdomain"],
//       caches: ["cookie"],
//     },
//     backend: {
//       loadPath: "/assets/locales/{{lng}}/translation.json",
//     },
//     react: { useSuspense: false },
//   });
ReactDOM.render(<Provider store={store}>
  <App />
</Provider>, document.getElementById("root"));

serviceWorkerRegistration.register();
