import { createSlice } from '@reduxjs/toolkit'

export const languageSlice = createSlice({
  name: 'language',
  initialState: {
    value: '',
  },
  reducers: {

    increament:  (state, data) => {
       
        state.value = data.payload;
      },
 
    onChangeLanguage: (state, action) => {
     // state.value = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { increament, onChangeLanguage } = languageSlice.actions


export const selectedLanguage = state => state.languageReducer.value;

export default languageSlice.reducer;
